@use "src/styles/breakpoints";

$text-colors: #fff; // text color should be white on both light and dark theme
$button-hover-color: rgba(
  $color: #000000,
  $alpha: 0.3,
);

.container {
  position: absolute;
  inset-block-start: 0;
  inset-inline: 0;

  display: flex;
  justify-content: flex-end;

  box-sizing: border-box;
  padding-inline: calc(1.3 * var(--spacing-medium));
  padding-block: var(--spacing-medium);
  @include breakpoints.tablet {
    padding-inline: calc(1.3 * var(--spacing-mega));
  }
}

.playRadioSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.stationTitle {
  margin-block-start: var(--spacing-medium);
  color: $text-colors;
}

.editStationButton {
  margin-inline-end: var(--spacing-xsmall);
  color: $text-colors;
  text-decoration: underline;
}

.playPauseButton {
  background-color: transparent;
  border: none;
  color: $text-colors;
  &:hover {
    color: $text-colors;
    background-color: $button-hover-color;
  }
}
